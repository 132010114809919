const lottie = require('lottie-web');

document.addEventListener("DOMContentLoaded", () => {

    if (document.getElementById('intro__doodle')) {
        lottie.loadAnimation({
            container: document.getElementById('intro__doodle'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: document.getElementById('intro__doodle').dataset.path
        });
    }

});
